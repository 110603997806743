@use "src/scss/colors" as colors;
@use "src/scss/mixins" as mixins;

.summary {
    max-width: 760px;
    margin: 0 auto; // TODO: removed 50px?

    p {
        padding: 24px 0;
        color: colors.$body-text;

        a {
            text-decoration: none;
            color: colors.$pink-secondary;

            &:hover {
                border-bottom: colors.$pink-secondary 2px dotted;
            }
        }
    }
}

.scroll-container {
    width: 60px;
    height: 72px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 50px;
    cursor: pointer;

    .arrows {
        width: 60px;
        height: 72px;
        position: relative;
    }

    .arrows path {
        stroke: colors.$light-blue;
        fill: transparent;
        stroke-width: 1px;
        animation: arrow 2s infinite;
    }

    @keyframes arrow {
        0% {
            opacity: 0
        }
        40% {
            opacity: 1
        }
        80% {
            opacity: 0
        }
        100% {
            opacity: 0
        }
    }

    .arrows path.a1 {
        animation-delay: -1s;
    }

    .arrows path.a2 {
        animation-delay: -0.5s;
    }

    .arrows path.a3 {
        animation-delay: 0s;
    }
}

.top-screen {
    height: 100vh;
    width: 100%;
}

.projects {
    display: flex;
    justify-content: center;

    max-width: 800px;
    flex-wrap: wrap;

    padding-bottom: 25vh;

    &.showing {
        .Project {
            opacity: 1;
        }
    }
}
