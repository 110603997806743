@use "src/scss/colors" as colors;
@use "src/scss/mixins" as mixins;

.Header {
    text-align: center;

    h1 {
        font-size: 80px;
        color: #FFF;
        margin: 25vh 0 0;

        @include mixins.small {
            margin-top: 10vh;
        }
    }

    .icon-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        transition: color 300ms ease-in;

        .icon {
            &:not(:first-child), &:not(:last-child) {
                margin: 0 32px;
            }

            margin-top: 8px;
        }

        svg {
            width: 32px;
            height: 32px;
            color: colors.$pink;

            &:hover {
                transition: color 350ms ease-out;
                color: colors.$pink-secondary;
            }
        }
    }
}