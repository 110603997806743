@use "src/scss/colors" as colors;
@use "src/scss/mixins" as mixins;

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;

  background: colors.$dark-blue;
  padding: 0 24px;
}

p {
  font-size: 18px;
  line-height: 30px;
  font-family: 'Montserrat', 'Roboto', sans-serif;
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
}

.main-content-wrapper {
  align-self: center;
  justify-self: center;

  display: block;
  flex: 1 0 auto;

  width: 100%;
  max-width: 800px;
}
